.projects-section {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    transition: background 0.5s ease-in-out;
}
.projects-section__centered {
    max-width: 650px;
}
.projects-section__title {
    font-family: 'Bebas Neue';
    font-size: 30px;
    color: white;
    background-color: rgb(21, 191, 109);
    position: relative;
    margin-top: -60px;
    padding-inline: 20px;
}

@media screen and (max-width: 760px) {
    .projects-section__centered {
        width: 100%;
    }
}